import axios from 'axios';

const url="https://api.zipbill.io/invoicelink";

async function callAPI(dataToSend) {
    axios.post(url, dataToSend)
    .then((response) => {
    // handle the response
        console.log(response);
    })
    .catch((error) => {
    // handle errors
    console.log(error);
    });
}

export { callAPI }